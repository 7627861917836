<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item">
        <DefaultInput
            :label="'Id'"
            :type="'text'"
            v-model="consolidationId"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_productCount'])"></span>
        <DefaultInput
            :label="$t('importO_productCount.localization_value.value')"
            :type="'number'"
            v-model="productCount"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['consolidations_trackingNumber'])"></span>
        <DefaultInput
            :label="$t('consolidations_trackingNumber.localization_value.value')"
            :type="'text'"
            v-model="userTrackingNumber"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :functionSearch="functionSearch"
            @change="changeUsersFilter"
            :selected="userName"
            :userEmail="true"
            :typeSelect="'users'"
        />
      </div>

<!--      <div class="table-filter__item">-->
<!--        <DefaultInput-->
<!--            :label="$t('consolidations_userTrackingNumber.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="userTrackingNumber"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['consolidations_created'])"></span>
        <DatePickerDefault
            :label="$t('consolidations_created.localization_value.value')"
            v-model="createdDate"
        >
          <template slot="body">
            <date-picker
                v-model="createdDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['consolidations_shipped'])"></span>
        <DatePickerDefault
            :label="$t('consolidations_shipped.localization_value.value')"
            v-model="shippedDate"
        >
          <template slot="body">
            <date-picker
                v-model="shippedDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['consolidations_received'])"></span>
        <DatePickerDefault
            :label="$t('consolidations_received.localization_value.value')"
            v-model="receivedDate"
        >
          <template slot="body">
            <date-picker
                v-model="receivedDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_PaidFilter'])"></span>
        <DefaultSelect
            :label="$t('fbm_PaidFilter.localization_value.value')"
            :options="paidOptions"
            :optionsLabel="'name'"
            :selected="getPaidTypeSelected"
            @change="changePaidType"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_promoCodes'])"></span>
        <DefaultSelect
            :label="$t('common_promoCodes.localization_value.value')"
            :options="promoCodeOptions"
            :optionsLabel="'name'"
            :selected="getShowPromoCodeSelected"
            @change="changeShowPromoCode"
        />
      </div>

      <div class="table-filter__item"
           v-if="!isAdmin && $store.getters.getConsolidationCommonList.send_from_list && $store.getters.getConsolidationCommonList.send_from_list.data && $store.getters.getConsolidationCommonList.send_from_list.data.length"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_promoCodes'])"></span>
        <DefaultSelect
            :label="$t('fbm_SendFromInformation.localization_value.value')"
            :options="$store.getters.getConsolidationCommonList.send_from_list.data"
            :optionsLabel="'company_name'"
            :selected="_.find($store.getters.getConsolidationCommonList.send_from_list.data, {company_name: sendFrom})"
            @change="changeSendFrom"
        />
      </div>


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ConsolidationFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      filterGetParams: Object,
  },

    computed: {
      getPaidTypeSelected() {
        let foundSelected = this._.find(this.paidOptions, {value: this.paid})
        return foundSelected ? foundSelected : ''
      },

      getShowPromoCodeSelected() {
        let foundSelected = this._.find(this.promoCodeOptions, {value: this.promoCode})
        return foundSelected ? foundSelected : this.promoCodeOptions[1]
      },
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        consolidationId: this.filterGetParams.consolidationId ? this.filterGetParams.consolidationId : '',
        productCount: this.filterGetParams.productCount ? this.filterGetParams.productCount : '',

        createdDate: this.filterGetParams.createdDate ? this.generateFilterDate(this.filterGetParams.createdDate, 'MM/DD/YY') : '',
        shippedDate: this.filterGetParams.shippedDate ? this.generateFilterDate(this.filterGetParams.shippedDate, 'MM/DD/YY') : '',
        receivedDate: this.filterGetParams.receivedDate ? this.generateFilterDate(this.filterGetParams.receivedDate, 'MM/DD/YY') : '',

        userTrackingNumber: this.filterGetParams.userTrackingNumber ? this.filterGetParams.userTrackingNumber : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        paid: this.filterGetParams.paid ? this.filterGetParams.paid : '',

        promoCode: this.filterGetParams.promoCode ? this.filterGetParams.promoCode : '',

        sendFrom: this.filterGetParams.sendFrom ? this.filterGetParams.sendFrom : '',

        options: [],

        filterCounts: [
          'consolidationId',
          'productCount',
          'createdDate',
          'shippedDate',
          'receivedDate',
          'userTrackingNumber',
          'trackingNumber',
          'userName',
          'userId',
          'paid',
          'promoCode',
          'sendFrom',
        ],

        paidOptions: [
          {
            name: this.$t('fbm_paidAll.localization_value.value'),
            value: ''
          },
          {
            name: this.$t('fbm_paidYes.localization_value.value'),
            value: '1'
          },
          {
            name: this.$t('fbm_paidNo.localization_value.value'),
            value: '0'
          },
        ],

        promoCodeOptions: [
          {
            name: this.$t('fbm_paidYes.localization_value.value'),
            value: '1'
          },
          {
            name: this.$t('fbm_paidNo.localization_value.value'),
            value: '0'
          },
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.consolidationId = newVal.consolidationId ? newVal.consolidationId : ''
        this.productCount = newVal.productCount ? newVal.productCount : ''

        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''

        this.createdDate = newVal.createdDate ? this.generateFilterDate(newVal.createdDate, 'MM/DD/YY') : ''
        this.shippedDate = newVal.shippedDate ? this.generateFilterDate(newVal.shippedDate, 'MM/DD/YY') : ''
        this.receivedDate = newVal.receivedDate ? this.generateFilterDate(newVal.receivedDate, 'MM/DD/YY') : ''

        this.userTrackingNumber = newVal.userTrackingNumber ? newVal.userTrackingNumber : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''

        this.paid = newVal.paid ? newVal.paid : ''
        this.promoCode = newVal.promoCode ? newVal.promoCode : ''
        this.sendFrom = newVal.sendFrom ? newVal.sendFrom : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, true)
      },

      changeUsersFilter(value) {
        console.log(value);
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.createdDate = this.changeDateParams(this, 'createdDate')
        data.shippedDate = this.changeDateParams(this, 'shippedDate')
        data.receivedDate = this.changeDateParams(this, 'receivedDate')

        this.$emit('changeFilter', data)
      },

      changePaidType(val) {
        this.paid = val.value
      },

      changeShowPromoCode(val) {
        this.promoCode = val.value
      },

      changeSendFrom(val) {
        this.sendFrom = val.company_name
      },
    },
  }
</script>

<style scoped>

</style>
